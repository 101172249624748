<template>
  <div
    @click="removeLists"
  >
    <p style="font-size: 32px; font-weight: bold; border-bottom: 1px solid lightgray;">
      {{ $t("Trucks") }}
    </p>
    <div style="display: flex; flex-direction: row;">
      <div style="margin-top: 20px">
        <div class="black">
          <b-icon-search
            v-if="searchPlate == ''"
            class="search1"
            style="position: absolute; left: 5px; top: 10px"
          />
          <b-icon-x-circle
            v-else-if="searchPlate != '' && !noSearch"
            class="search1"
            style="position: absolute; margin-top: -6px; right: 10px; top: 17px; cursor: pointer"
            @click="searchPlate = ''"
          />
          <input
            v-model="searchPlate"
            type="text"
            autocomplete="off"
            name="search-box"
            :placeholder="$t(`Truck'sPlate`)"
            :style="
              getFilterPlateTruck.length > 0 && noSearch
                ? 'border-radius: 8px 8px 8px 8px'
                : 'border-radius: 8px 8px 8px 8px; padding-left: 25px;'"
            @keyup="inputChanged"
            @keydown.down="onArrowDown"
            @keydown.up="onArrowUp"
          >
        </div>
        <div
          v-if="getFilterPlateTruck.length > 0 && noSearch"
          ref="scrollContainer"
          class="dropdrop"
          :style="getFilterPlateTruck.length > 7 ? 'height: 210px' : 'height: auto'"
        >
          <div
            v-for="(result, index) in getFilterPlateTruck"
            :key="index"
            ref="options"
            class="search-item"
            :class="{ 'is-active': index === arrowCounter }"
            @click="
              searchByOn(result.registrationPlate);
              noSearch = false;"
          >
            <p style="margin: 0">
              {{ result.registrationPlate }}
            </p>
          </div>
        </div>
      </div>
      <div style="display: flex; flex-direction: row">
        <button
          v-b-modal.add-truck
          class="button"
          style="border-radius: 6px; height: 36px; margin-top: 22px; margin-right: 20px; width:220px"
          @click="fetchFiliale"
        >
          <b-icon-plus />
          {{ $t('AddTruck') }}
        </button>
        <b-form-group
          id="input-group-9"
          :label="$t('Filter')"
          label-for="input-9"
          style="margin-bottom: 10px; width: 200px"
        >
          <b-form-select
            ref="storeSelect"
            v-model="store"
            :clearable="false"
            required
            style="width:100%;padding: 8.5px; color: #82868c; border-radius: 8px; border: none; box-shadow: 0px 0px 10px rgb(40 41 61 / 14%);"
            :options="selectFiliale"
            label="storeName"
            :value="storeId"
            placeholder="Select"
            aria-describedby="input-1-live-feedback"
            @search="onSearchM"
            @clear="clearSelection"
          />
          <li
            slot="list-footer"
            class="pagination"
            style="display: flex; width: 100%; justify-content: center"
          >
            <button
              type="button"
              :style="
                pageM == 1
                  ? 'width: 50%; border: none;'
                  : 'width: 50%; border: none; background-color: #242f6e; color: white'
              "
              :disabled="pageM == 1"
              @click="prevMeter"
            >
              {{ $t("Prev") }}
            </button>
            <button
              :style="
                pageM == getTotalItemsForFiliales
                  ? 'width: 50%; border: none;'
                  : 'width: 50%; border: none; background-color: #242f6e; color: white'
              "
              type="button"
              :disabled="pageM == getTotalItemsForFiliales"
              @click="nextMeter"
            >
              {{ $t("Next") }}
            </button>
          </li>
        </b-form-group>
      </div>

      <div class="activeInactivebtn">
        <div
          class="active-inactive-con"
        >
          <button
            class="button aktiv"
            :disabled="isactive == true"
            @click="activeF()"
          >
            {{ $t("ActiveTrucks") }}
          </button>
          <button
            class="button inaktiv"
            :disabled="isactive == false"
            @click="activeF(), statusFalse()"
          >
            {{ $t("InactiveTrucks") }}
          </button>
        </div>
        <!-- <div class="search-toggle">
          <p>{{ $t("SearchBy") }}</p>
          <div>
            <p>
              {{ $t(`Truck'sPlate`) }}
            </p>
          </div>
        </div> -->
      </div>
    </div>
    <div style="width: 100%; padding-top: 16px; margin-top: -3px">
      <table
        class="team_table"
        style="width: 100% !important; box-shadow: 0px 7px 20px rgb(40 41 61 / 8%); text-align: left;;"
      >
        <thead>
          <tr>
            <th style="padding: 10px; border-radius: 10px 0px 0px 0px">
              {{ $t('No.') }}
            </th>
            <th style="padding: 10px; border-radius: 0px 0px 0px 0px">
              {{ $t('BrandName') }}
            </th>
            <th style="padding: 10px">
              {{ $t('Model') }}
            </th>
            <th style="padding: 10px">
              {{ $t('VariantName') }}
            </th>
            <th style="padding: 10px">
              {{ $t('LicencePlate') }}
            </th>
            <th style="padding: 10px">
              {{ $t('ChasisNumber') }}
            </th>
            <th style="padding: 10px">
              {{ $t('ProduceYear') }}
            </th>
            <th style="padding: 10px">
              {{ $t('FilialeName') }}
            </th>
            <th style="padding: 10px; border-radius: 0px 10px 0px 0px; text-align: left;">
              {{ $t('Edit') }}
            </th>
          </tr>
        </thead>
        <tbody v-if="isactive == true">
          <tr
            v-for="(name,index) in getTrucksPagination"
            :key="name.truckId"
            style="border-bottom: 1px solid #dcdfe6; color: #606266;"
            @click="matProps(filiale)"
          >
            <td style="padding: 10px">
              {{ getTotalItemsForTrucks - (pageNumber - 1) * pageSize - index }}.
            </td>
            <td style="padding: 10px">
              {{ name.brandName }}
            </td>
            <td style="padding: 10px">
              {{ name.modelName }}
            </td>
            <td style="padding: 10px">
              {{ name.variantName }}
            </td>
            <td style="padding: 10px">
              {{ name.registrationPlate }}
            </td>
            <td style="padding: 10px">
              {{ name.chassisNumber }}
            </td>
            <td style="padding: 10px">
              {{ dateModified(name.year) }}
            </td>
            <td style="padding: 5px">
              <p
                class="p-0 m-0 ml-50"
                @click="matPropss(name.truckId); storeNAME(name.storeName)"
              >
                {{ name.storeName }}
              </p>
              <b-button
                v-if="name.storeName == null"
                v-b-modal.assign-store-truck
                style="max-height: 35px; font-size: small;"
                @click="matPropss(name.truckId); loadFreeAssignedFiliales()"
              >
                {{ $t('Assign') }}
              </b-button>
            </td>
            <td
              v-b-toggle.edit-truck-toggle
              style="padding: 10px; text-align: left; cursor: pointer"
              @click="openEdit(name); storeNAME(name.storeName);loadFreeAssignedFiliales(); disableStore(name.truckId); test1(name.storeId)"
            >
              <b-icon-pencil />
            </td>
            <td style="text-align: left;">
              <p
                v-b-modal.deactivate-truck
                class="p-0 m-0 ml-50"
                @click="getIDTruck(name.truckId), getRoutesPerTrucks()"
              >
                <toggle-button
                  :value="true"
                  :sync="true"
                  color="#0071AE"
                />
              </p>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="isactive == false">
          <tr
            v-for="(name,index) in getTrucksPagination"
            :key="name.truckId"
            style="border-bottom: 1px solid #dcdfe6; color: #606266; cursor: pointer"
            @click="matPropss(filiale)"
          >
            <td style="padding: 10px">
              {{ getTotalItemsForTrucks - (pageNumber - 1) * pageSize - index }}.
            </td>
            <td style="padding: 10px">
              {{ name.brandName }}
            </td>
            <td style="padding: 10px">
              {{ name.modelName }}
            </td>
            <td style="padding: 10px">
              {{ name.variantName }}
            </td>
            <td style="padding: 10px">
              {{ name.registrationPlate }}
            </td>
            <td style="padding: 10px">
              {{ name.chassisNumber }}
            </td>
            <td style="padding: 10px">
              {{ dateModified(name.year) }}
            </td>
            <td style="padding: 10px">
              <p
                v-b-modal.edit-store-truck
                class="p-0 m-0 ml-50"
                @click="matPropss(name.truckId); "
              >
                {{ name.storeName }}
              </p>
              <b-button
                v-if="name.storeName == null"
                v-b-modal.assign-store-truck
                style="max-height: 35px; font-size: small;"
                @click="matPropss(name.truckId); loadFreeAssignedFiliales()"
              >
                {{ $t('Assign') }}
              </b-button>
            </td>
            <td
              v-b-modal.edit-truck
              style="padding: 10px; text-align: left; cursor: pointer"
              @click="openEdit(name); storeName(name.storeName)"
            >
              <b-icon-pencil />
            </td>
            <td>
              <p
                v-b-modal.activate-truck
                class="p-0 m-0 ml-50"
                style="text-align: left;"
                @click="getIDTruck(name.truckId)"
              >
                <toggle-button
                  :value="false"
                  :sync="true"
                  color="#0071AE"
                />
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <b-pagination
        v-if="isactive == true"
        v-model="pageNumber"
        :total-rows="getTotalItemsForTrucks"
        :per-page="pageSize"
        first-number
        last-number
        align="right"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
      >
        <template #prev-text>
          <span>{{ $t("Previous") }}</span>
        </template>
        <template #next-text>
          <span>{{ $t("Next") }}</span>
        </template>
      </b-pagination>
      <b-pagination
        v-else
        v-model="page"
        :total-rows="getTotalItemsForTrucks"
        :per-page="pageSize"
        first-number
        last-number
        align="right"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
      >
        <template #prev-text>
          <span>{{ $t("Previous") }}</span>
        </template>
        <template #next-text>
          <span>{{ $t("Next") }}</span>
        </template>
      </b-pagination>
    </div>
    <AddTruckModal @click="addTruckk" />
    <EditTruckModal
      :item="storeNamee"
      :edit-truck="truck"
      :store-idd="testt"
      @edit="editTruck"
    />
    <DeactivateTruckModal
      :item="Id"
      @click="deactivateTruck"
    />
    <ActivateTruck
      :item="Id"
      @click="activateTruck"
    />
    <AssignStoreToTruck
      :truck-id="truckId"
      @assignFiliale="handleAssignStore"
    />
    <EditStoreToTrucks
      :item="storeNamee"
      :truck-id="truckId"
      @assignFiliale="handleAssignStore"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// eslint-disable-next-line no-unused-vars
import AddTruckModal from '@/components/trucks/modals/AddTruckModal.vue';
import EditTruckModal from '@/components/trucks/modals/EditTruckModal.vue';
import DeactivateTruckModal from '@/components/trucks/modals/DeactivateTruck.vue'
import AssignStoreToTruck from '@/components/trucks/modals/AssignStoreToTruck.vue'
import EditStoreToTrucks from '@/components/trucks/modals/EditStoreToTrucks.vue'
// import moment from 'moment';
import moment from 'moment-timezone';
import ActivateTruck from '@/components/trucks/modals/ActivateTruck.vue'

export default {
  components: {
    AddTruckModal,
    EditTruckModal,
    DeactivateTruckModal,
    AssignStoreToTruck,
    EditStoreToTrucks,
    ActivateTruck,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['materialId', 'createOrder'],
  data() {
    return {
      initialId: '',
      store: 'all',
      status: true,
      statuss: false,
      storeId: null,
      classNameCh: '',
      searchTerm: '',
      rows: [],
      testt: null,
      materialObject: {},
      pageM: 1,
      editMat: {},
      page: 1,
      isactive: true,
      pageSize: 15,
      pageNumber: 1,
      test: null,
      noSearch: false,
      searchPlate: '',
      storeNamee: '',
      arrowCounter: 0,
      truckId: '',
      Id: '',
      enterPressed: false,
      truck: {
        truckId: '',
        brandName: '',
        modelName: '',
        variantName: '',
        registrationPlate: '',
        chassisNumber: null,
        year: '',
        storeId: null,
        storeName: '',
      },
      color: {
        mainDressCategoryColorId: '',
        name: '',
      },
      getFiliale: {
        filialeName: 'Mercedes',
        address: 'Sprinter',
        todayRoutes: 'LU-2142131',
        trucks: '12/03/2018',
        totalEmployee: '12/03/2023',
      },
    };
  },

  computed: {
    ...mapGetters([
      'getFiliales',
      'getTotalItemsForFiliales',
      'getTotalItemsForFiliales',
      'getCurrentPageForStore',
      'getTrucksPagination',
      'getTrucksByStore',
      'getFilialesForTrucks',
      'getFilterPlateTruck',
      'getTotalItemsForTrucks',
      'getRoutersForTrucks',
      'getStoresForDropdown',
    ]),
    selectFiliale() {
      const filiales = this.getStoresForDropdown
        .filter((option) => { return option.storeGLN && option.storeName }) // Filter out objects without value or text
        .map((option) => {
          return {
            value: option.storeId,
            text: option.storeName,
          }
        });

      return filiales;
    },
  },
  watch: {
    pageNumber(value) {
      this.loadTrucksPaginationWithoutStore({
        storeId: 'all',
        status: this.status,
        pageNumber: value,
        pageSize: this.pageSize,
      });
    },
    store: {
      handler(value) {
        if (value == '' && this.store.storeId != '') {
          this.loadTrucksPaginationWithoutStore({
            storeId: value,
            status: this.status,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          });
        } else if (value == 'unassigned') {
          this.loadTrucksPaginationWithoutStore({
            storeId: value,
            status: this.status,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          });
        } else if (value == 'all') {
          this.loadTrucksPaginationWithoutStore({
            storeId: value,
            status: this.status,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          });
        } else if (this.store.storeId != '') {
          this.loadTrucksPaginationWithoutStore({
            storeId: value,
            status: this.status,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          });
        } else {
          this.fetchTrucks(value.storeId).then(() => {
            this.loadTrucksPaginationWithoutStore({
              storeId: value,
              status: this.status,
              pageNumber: this.pageNumber,
              pageSize: this.pageSize,
            });
          });
        }
      },
      immediate: true,
    },
    searchPlate(value) {
      if (value == '' && this.store == 'all') {
        this.removeLists();
        this.loadTrucksPaginationWithoutStore({
          storeId: 'all',
          registrationPlate: null,
          status: this.status,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        });
        this.noSearch = true;
      } else if (value == '' && this.store == 'unassigned') {
        this.removeLists();
        this.loadTrucksPaginationWithoutStore({
          storeId: this.store,
          registrationPlate: null,
          status: this.status,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        });
        this.noSearch = true;
      } else if (value == '' && this.store.storeId != '') {
        this.removeLists();
        this.loadTrucksPaginationWithoutStore({
          storeId: this.store.storeId,
          registrationPlate: null,
          status: this.status,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        });
        this.noSearch = true;
      } else if (value != '' && this.store == 'all') {
        this.loadFilteredPlateTruck({
          filter: this.store.toLowerCase(),
          registrationPlate: value,
        });
        this.noSearch = true;
      } else if (value != '' && this.store == 'unassigned') {
        this.loadFilteredPlateTruck({
          filter: this.store.toLowerCase(),
          registrationPlate: value,
        });
        this.noSearch = true;
      } else if (value != '' && this.store.storeId != '') {
        this.loadFilteredPlateTruck({
          filter: this.store.storeId.toLowerCase(),
          registrationPlate: value,
        });
        this.noSearch = true;
      } else if (this.store == 'unassigned') {
        this.loadFilteredPlateTruck({
          filter: this.store.toLowerCase(),
          registrationPlate: value,
        });
        this.noSearch = true;
      } else if (this.store == 'all') {
        this.loadFilteredPlateTruck({
          filter: this.store.toLowerCase(),
          registrationPlate: value,
        });
        this.noSearch = true;
      } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
        return;
      } else {
        this.loadFilteredPlateTruck({
          filter: this.store.storeId.toLowerCase(),
          registrationPlate: value,
        });
        this.noSearch = true;
      }
    },
  },
  mounted() {
    this.fetchTrucks();
    this.fetchFilialeForTrucks();
  },
  methods: {
    ...mapActions([
      'loadFilialePagination',
      'changeLoadingtoTrue',
      'loadFilteredStoreName',
      'addTruck',
      'loadTrucksPaginationWithoutStore',
      'loadTrucksByStore',
      'loadFilialePaginationForTrucks',
      'loadFilteredPlateTruck',
      'resetFilteredPlate',
      'editTruckById',
      'loadToursForTrucks',
      'deactivateAndActivateTruck',
      'assignTruckStore',
      'loadIsBusyTruck',
      'stores_for_Dropdown',
    ]),
    async fetchTrucks() {
      try {
        // this.changeLoadingtoTrue(false);


        await this.loadTrucksPaginationWithoutStore({
          storeId: 'all',
          status: this.status,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        }).then(() => {

          // this.changeLoadingtoTrue(true);
        });
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false);
      }
    },
    getRoutesPerTrucks() {
      this.loadToursForTrucks({
        truckId: this.Id,
        startShift: new Date().toJSON().slice(0, 10).replace(/-/g, '.'),
      })
    },
    getIDTruck(value) {
      // console.log('ID', value)
      this.Id = value
      // return value
    },
    async loadFreeAssignedFiliales() {
      await this.loadFilialePagination({
        status: this.status,
        pageSize: 100,
        pageNumber: 1,
      })
    },
    storeNAME(value) {
      this.storeNamee = value
      // this.truck.storeId = test
    },
    test1(value) {
      this.testt = value
      // this.truck.storeId = value
    },
    async fetchFiliale() {
      try {
        //       // this.changeLoadingtoTrue(false);


        await this.loadFilialePagination({
          status: this.status,
          pageSize: 100,
          pageNumber: 1,
        }).then(() => {

          // this.changeLoadingtoTrue(true);
        });
      } catch (e) {
        console.log(e);
        //       // this.changeLoadingtoTrue(false);
      }
    },
    async handleAssignStore(obj) {
      await this.assignTruckStore({
        object: obj,
        successCallback: () => {
          this.loadTrucksPaginationWithoutStore({
            storeId: 'all',
            status: this.status,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          });
          // this.freeTeamLeaders();
        },
      });
    },
    async filiale() {
      try {
        await this.loadUsersFullNamesById('TeamLeader').then(() => {
        });
      } catch (e) {
        console.log(e);
      }
    },
    async fetchFilialeForTrucks() {
      try {
        await this.stores_for_Dropdown({
          amountOfRecords: 100,
        }).then(() => {

          // this.changeLoadingtoTrue(true);
        });
      } catch (e) {
        console.log(e);
        //       // this.changeLoadingtoTrue(false);
      }
    },
    async deactivateTruck(value) {
      // console.log('1235', value)
      try {
        await this.deactivateAndActivateTruck({
          truckId: value.truckId,
          status: value.status,
          successCallback: () => {
            this.loadTrucksPaginationWithoutStore({
              status: this.status,
              pageNumber: this.pageNumber,
              pageSize: this.pageSize,
            });
          // this.freeTeamLeaders();
          },
        }).then(() => {

        });
      } catch (e) {
        console.log(e);
      }
    },
    async activateTruck(value) {
      try {
        await this.deactivateAndActivateTruck({
          truckId: value.truckId,
          status: true,
          successCallback: () => {
            this.loadTrucksPaginationWithoutStore({
              status: this.status,
              pageNumber: this.pageNumber,
              pageSize: this.pageSize,
            });
          // this.freeTeamLeaders();
          },
        }).then(() => {

        });
      } catch (e) {
        console.log(e);
      }
    },
    async addTruckk(obj) {
      await this.addTruck({
        object: obj,
        successCallback: () => {
          this.loadTrucksPaginationWithoutStore({
            storeId: 'all',
            status: this.status,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          }).then((res) => {
            console.log(res);
          });
        },
      });
    },
    async handleAssignFoliale(obj) {
      await this.assignTeamLeader({
        object: obj,
        successCallback: () => {
          this.loadTrucksPaginationWithoutStore({
            status: this.status,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          })
        },
      });
    },

    async editTruck(obj) {
      // console.log('rreew', obj);
      await this.editTruckById({
        object: obj,
        successCallback: () => {
          this.loadTrucksPaginationWithoutStore({
            storeId: 'all',
            status: this.status,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          });
        },
      });
    },
    disableStore(value) {
      this.loadIsBusyTruck(value)
    },
    async activeF() {
      this.isactive = !this.isactive;
      this.searchUser = '';
      if (!this.isactive) {
        await this.loadTrucksPaginationWithoutStore({
          storeId: 'all',
          status: this.statuss,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        });
      } else {
        await this.loadTrucksPaginationWithoutStore({
          storeId: 'all',
          status: this.status,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        });
      }
    },

    openEdit(value) {
      // console.log('vaa', value)
      this.truck.truckId = value.truckId;
      this.truck.brandName = value.brandName;
      this.truck.modelName = value.modelName;
      this.truck.variantName = value.variantName;
      this.truck.registrationPlate = value.registrationPlate;
      this.truck.chassisNumber = value.chassisNumber;
      this.truck.year = value.year;
      this.truck.storeName = value.storeName;
      // this.truck.storeId = value.storeId
    },
    edit(item) {
      this.color.mainDressCategoryColorId = item.mainDressCategoryColorId;
      this.color.name = item.colorName;
      this.$bvModal.show('modal-edit-color');
    },
    toggleBtn(truee) {
      this.sync = truee;
    },
    matProps(color) {
      this.$emit('click', color);
      this.classNameCh = color.colorName;
    },
    async getMVbyMeter(page, pageS) {
      await this.loadFilialePagination({
        status: this.status,
        pageNumber: page,
        pageSize: pageS,
      });
    },
    nextMeter() {
      if (this.pageM < this.getTotalItemsForFiliales) {
        this.pageM += 1;
        this.getMVbyMeter(this.pageM, this.pageSize);
      }
    },
    prevMeter() {
      if (this.pageM > 1) {
        this.pageM -= 1;
        this.getMVbyMeter(this.pageM, this.pageSize);
      }
    },
    async onSearchM(query) {
      await this.loadFilteredStoreName({
        name: query,
      });
      if (query == '') {
        this.testResetMV();
        this.getMVbyMeter(this.getCurrentPageForStore, this.pageSize);
      }
    },
    onArrowDown(ev) {
      ev.preventDefault();
      if (this.arrowCounter < this.getFilterPlateTruck.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault();
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling();
      }
    },

    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') {
        return;
      }
      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true;
        this.searchByOn(this.getFilterPlateTruck[this.arrowCounter].registrationPlate);
        this.removeLists();
        this.arrowCounter = 0;
        setTimeout(() => {
          this.enterPressed = false;
        }, 1000);
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    matPropss(color) {
      this.truckId = color;
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH);
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    async searchByOn(value) {
      // console.log('vall', value)
      this.searchPlate = value;
      this.noSearch = false;
      await this.loadTrucksPaginationWithoutStore({
        storeId: 'all',
        registrationPlate: value,
        status: this.status,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      });
    },
    removeLists() {
      this.noSearch = false;
      this.resetFilteredPlate();
    },
    async clearSelection() {
      try {
        await this.loadTrucksPaginationWithoutStore({
          storeId: 'all',
          status: this.status,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        }).then(() => {

        });
      } catch (e) {
        console.log(e);
      }
      // this.store = null;
    },
    resetValue() {
      this.$refs.storeSelect.reset();
    },
    dateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY');
    },
  },
};
</script>

<style scoped lang="scss">

h1 {
  margin: 2em 0 0;
  font-size: 2em;
  font-family: helvetica, ariel, sans-serif;
  text-align: left;
}

span{
  color: black;
}
p{
  color: black;
}
table td{
  color: black;
}

h2 {
  margin: 1em 0 2em;
  font-size: 1.4em;
  font-family: helvetica, ariel, sans-serif;
  text-align: left;
}

.codepen-wrapper {
  width: 25%;
  margin: 0 37.5%;
}

// this is the stuff you want.

.registration-ui {
  background: linear-gradient(to bottom, #f8d038 0%, #f5ca2e 100%);
  padding: 0.25em 1em 0.25em 1.75em;
  font-weight: bold;
  font-size: 2em;
  border-radius: 5px;
  border: 1px solid #000;
  box-shadow: 1px 1px 1px #ddd;
  position: relative;
  font-family: helvetica, ariel, sans-serif;
}

.registration-ui:before {
  content: "GB";
  display: block;
  width: 30px;
  height: 100%;
  background: #0071AE;
  position: absolute;
  top: 0;
  border-radius: 5px 0 0 5px;
  color: #f8d038;
  font-size: 0.5em;
  line-height: 85px;
  padding-left: 5px;
}

.aktiv{
  margin-right: 15px;
  margin-left: 15px;
  border-radius: 6px;
  width:220px
}

.inaktiv{
  border-radius: 6px;
  width:220px
}

.search-item{
  cursor: pointer;
}

.activeInactivebtn{
  display: flex;
  padding-right: 15px;
}

.active-inactive-con{
  margin-right: 15px;
  margin-top: 20px;
}
.registration-ui:after {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 5px;
  width: 20px;
  height: 20px;
  border-radius: 30px;
  border: 1px dashed #f8d038;
}
.search-toggle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.color-listing__wrapper {
  .mats-listing {
    margin-top: 0;
  }
  .table__search-input {
    visibility: hidden;
  }
  input {
    width: 100px;
  }
}
.button {
  margin-bottom: 15px !important;
  width: 195px;
}
.mat {
  display: flex;
  justify-content: space-between;
  align-content: center;
  &.active {
    background-color: #e8e8e8;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  span {
    margin-left: 10px;
    svg {
      font-size: 15px;
    }
  }
}
.team_table {
  font-size: 12px;
  border-radius: 8px 8px 8px 8px !important;
}

tr:last-child {
  border-bottom: none !important;
}
.button {
  margin-bottom: 15px !important;
  width: 195px;
}
.mat {
  display: flex;
  justify-content: space-between;
  align-content: center;
  &.active {
    background-color: #e8e8e8;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  span {
    margin-left: 10px;
    svg {
      font-size: 15px;
    }
  }
}
.team_table {
  font-size: 12px;
  border-radius: 8px 8px 8px 8px !important;
}

tr:last-child {
  border-bottom: none !important;
}

.search1 {
  padding: 0px;
}
.black {
  position: relative;
}
.black input {
  border: none;
  box-shadow: rgb(40 41 61 / 14%) -1px 4px 10px;
  height: 36px;
}
.dropdrop::-webkit-scrollbar {
  display: none;
}
.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: rgb(40 41 61 / 14%) -1px 4px 10px;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 600;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
.search-item:last-child {
  border-bottom: none;
}
.search-item:hover {
  background: #82868c;
  color: white;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
.is-active {
  background-color: #dedede;
}
.button {
  width: 195px;
}
.addEmployeeButton {
  width: 210px;
}

.dropdrop::-webkit-scrollbar {
  display: none;
}

.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: rgb(40 41 61 / 14%) -1px 4px 10px;
  z-index: 3;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}

.black {
  position: relative;
}

.black input {
  border: none;
  box-shadow: rgb(40 41 61 / 14%) -1px 4px 10px;
}

.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 600;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

@media screen and (max-width: 1400px) {
  .color-listing__wrapper {
    margin-left: 50px;
    width: 150px !important;
    .team_table {
      width: 150px !important;
    }
  }
  .color-listing__wrapper2 {
    margin-left: 10px;
    width: 200px !important;
    button {
      width: 150px;
      font-size: 13px;
      height: 36px;
    }
    .team_table {
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 1823px) {
  .color-listing__wrapper2 {
    width: 200px;
    button {
      width: 90%;
    }
    .team_table {
      width: 150px !important;
    }
  }
}

@media screen and (max-width: 1600px) {
  .scroll {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 1200px) {
  .button {
    margin-right: 10px;
    font-size: 12px;
    height: 36px;
    width: 170px;
  }
}

@media screen and (max-width: 1823px) {
  .color-listing__wrapper2 {
    width: 200px;
    button {
      width: 90%;
    }
    .team_table {
      width: 150px !important;
    }
  }
}
@media screen and (max-width: 1400px) {
  .color-listing__wrapper {
    margin-left: 50px;
    width: 150px !important;
    .team_table {
      width: 150px !important;
    }
  }
  .color-listing__wrapper2 {
    margin-left: 10px;
    width: 200px !important;
    button {
      width: 150px;
      font-size: 13px;
      height: 36px;
    }
    .team_table {
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 1041px){
  .activeInactivebtn{
    padding-right: 0px;
  }

  .active-inactive-con{
  margin-right: 0px;
  }
}

@media screen and (max-width: 896px) {
  .color-listing__wrapper {
    margin-left: 0 !important;
    flex-basis: 49%;
  }
}
.color-listing__wrapper {
  .mats-listing {
    margin-top: 0;
  }
  .table__search-input {
    visibility: hidden;
  }
  input {
    width: 100px;
  }
}

@media screen and (max-width: 763px) {
  .con {
    flex-wrap: wrap;
  }

  .active-inactive-con {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .button {
    height: auto;
  }
}

.is-active {
  background-color: #dedede;
}
</style>
